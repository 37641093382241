import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const firebaseConfig = {
  apiKey: "AIzaSyBWkW30ewmiC_H7LKcOC7ine_RFDFdB-Jk",
  authDomain: "sookler-plus-f5c55.firebaseapp.com",
  projectId: "sookler-plus-f5c55",
  storageBucket: "sookler-plus-f5c55.firebasestorage.app",
  messagingSenderId: "110525556477",
  appId: "1:110525556477:web:7a413f2839df43ee148094",
  measurementId: "G-ZFBZB83MJP",
};

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
const publicKey =
  "BDtbWBs5GXcXC_lmKjvXIXyB4iukrHBy1btykoxxoDJ-7Cs37LL8NDWB97_PJU5SxT5XO0U5Boxm-J0XmEWW1yU";

export const requestPermissions = async () => {
  const permission = await Notification.requestPermission();
  console.log("permission", permission);
  if (permission === "granted") {
    const token = await getToken(messaging, { vapidKey: publicKey });
    console.log("fcm token:", token);
  } else if (permission === "denied") {
    toast.error("You denied the notification", {
      anchorOrigin: {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      },
      autoHideDuration: 5000,
    });
  }
};

export const registerServiceWorker = () => {
  if ("serviceWorker" in navigator) {
    console.log("navigator", navigator);
    navigator.serviceWorker
      .register("/firebase-messaging-sw.js")
      .then(function (registration) {
        console.log(
          "Service Worker registered with scope:",
          registration.scope
        );
        return registration.scope;
      })
      .catch(function (err) {
        console.error("Service Worker registration failed:", err);
        return err;
      });
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    console.log("resolve firebaseInit: ", resolve);
    messaging.onMessage((payload) => {
      console.log("payload firebaseInit: ", payload);
      resolve(payload);
    });
  });
