import React from "react";

// ============ Auth ============
export const ResetPassword = React.lazy(() => import("pages/ResetPassword"));
export const PasswordEditForm = React.lazy(() =>
  import("pages/PasswordEditForm")
);
export const VerificationCodeForm = React.lazy(() =>
  import("pages/VerificationCodeForm")
);
// ============ Auth ============

export const VendorRouting = React.lazy(() =>
  import("modules/vendor/VendorRouting")
);
export const DriverShiftRouting = React.lazy(() =>
  import("modules/driver-shift/DriverShiftRouting")
);
export const UserRouting = React.lazy(() =>
  import("modules/users/UserRouting")
);
export const RoleRouting = React.lazy(() =>
  import("modules/role-permission/RoleRouting")
);
export const DriverRouting = React.lazy(() =>
  import("modules/driver/DriverRouting")
);
export const LocationRouting = React.lazy(() =>
  import("modules/location/LocationRouting")
);
export const DeliveryPathRouting = React.lazy(() =>
  import("modules/delivery-path/DeliveryPathRouting")
);
export const PagesRouting = React.lazy(() =>
  import("modules/pages/PagesRouting")
);

export const EmployeeRouting = React.lazy(() =>
  import("modules/employee/EmployeeRouting")
);

// ============ DashboardRouting ============
export const DashboardRouting = React.lazy(() =>
  import("../modules/dashboard/DashboardRouting")
);
export const CategoryRouting = React.lazy(() =>
  import("../modules/category/CategoryRouting")
);
export const AttachmentRouting = React.lazy(() =>
  import("../modules/attachment/AttachmentRouting")
);
export const ProductRouting = React.lazy(() =>
  import("../modules/product/ProductRouting")
);
export const AttributeRouting = React.lazy(() =>
  import("../modules/attribute/AttributeRouting")
);
export const ComplaintRouting = React.lazy(() =>
  import("../modules/complaint/ComplaintRouting")
);
export const SliderRouting = React.lazy(() =>
  import("../modules/slider/SliderRouting")
);
export const CouponsRouting = React.lazy(() =>
  import("../modules/coupon/CouponsRouting")
);
export const AddressRouting = React.lazy(() =>
  import("../modules/address/AddressRouting")
);
export const PlanRouting = React.lazy(() =>
  import("../modules/plan/PlanRouting")
);
export const PaymentMethodRouting = React.lazy(() =>
  import("../modules/payment_method/PaymentMethodRouting")
);
export const OrderRouting = React.lazy(() =>
  import("../modules/order/OrderRouting")
);
export const TransferRouting = React.lazy(() =>
  import("../modules/transfer/TransferRouting")
);
export const PackageRouting = React.lazy(() =>
  import("../modules/package/PackageRouting")
);
export const NotificationRouting = React.lazy(() =>
  import("../modules/notification/NotificationRouting")
);
export const DeliveryMethodRouting = React.lazy(() =>
  import("../modules/delivery-method/DeliveryMethodRouting")
);
export const ContactRouting = React.lazy(() =>
  import("../modules/contact/ContactRouting")
);
// ============ DashboardRouting ============
