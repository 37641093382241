import { _axios } from "interceptor/http-config";
import { HttpRequestInterceptor } from "interceptor/http-request.interceptor";

export const _NotificationsApi = {
  index: async ({ page, query, pageSize }) => {
    return _axios
      .get(
        `/notifications?page=${page}&pageSize=${pageSize}${
          query !== "" ? `&search=${query}` : ""
        }`,
        {
          headers: {
            ...HttpRequestInterceptor(),
          },
        }
      )
      .then((res) => res.data);
  },
  post: (formData) => _axios.post("/notifications", formData),

  updateFcmToken: ({ formData }) => _axios.post(`/notifications/fcm`, formData),
  delete: (id) => _axios.delete(`/notifications/${id}`).then((res) => res.data),
};
